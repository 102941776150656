import React from 'react';
import firebase, { firestore } from './firebase';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, ImageOverlay } from "react-leaflet";
import L, { CRS } from "leaflet";
import { useCollectionData } from 'react-firebase-hooks/firestore'
import $ from 'jquery'
import HoleMap from './data/augusta_hole1_4326.png'
import ProfilePic from './data/golf player.png'
import 'leaflet-easybutton';
import 'leaflet-easybutton/src/easy-button.css';
import "leaflet/dist/leaflet.css";
import { Modal, Dropdown } from 'react-bootstrap';
import './App.scss';

const minX = 280
const maxX = 1920
const mapMinZoom = 16
const mapMaxZoom = 18

const mappedValue = width => mapMinZoom + (mapMaxZoom - mapMinZoom) * ((width - minX) / (maxX - minX))

const padToFour = number => number <= 9999 ? ("000" + number).slice(-4) : number

const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1)

const getCircleMarker = latlng => L.circle(latlng, 0.000005, { opacity: 1, color: "white", fillColor: "white", fillOpacity: 1 })

const getMarkerPopup = content => L.popup({ offset: [0, -30], closeButton: false }).setContent(content)

const calculateDistances = polyline => {
  let distances = []
  let endPoint = polyline.slice(polyline.length - 1).pop()

  for (let i = 1; i < polyline.length - 1; i++) {
    distances.push({
      distanceToPre: L.latLng(polyline[i]).distanceTo(L.latLng(polyline[i - 1])),
      distanceToHole: L.latLng(polyline[i]).distanceTo(L.latLng(endPoint))
    })
  }

  return distances
}

const redMarker = L.icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  iconSize: [32, 48],
  iconAnchor: [16, 48]
})

const defaultMarker = L.icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png',
  iconSize: [32, 48],
  iconAnchor: [16, 48]
})

function App() {
  // firstore
  const query = firestore.collection('data').orderBy("fullName").limit(Math.min())
  const [data] = useCollectionData(query, { idField: 'id' })

  // Main page
  const [showAddPlayerPopup, setShowAddPlayerPopup] = useState(false)
  const [selectedPlayer, setSelectedPlayer] = useState(null)
  const [selectedRound, setSelectedRound] = useState(null)

  // Add player pop up page
  const firstNameRef = useRef()
  const lastNameRef = useRef()

  // Add shot pop up page
  const [showAppShotPopup, setShowAddShotPopup] = useState(false)
  const [selectedPlayerOnAddShot, setSelectedPlayerOnAddShot] = useState(null)
  const [selectedRoundOnAddShot, setSelectedRoundOnAddShot] = useState(null)
  const shotNoRef = useRef(1)
  const [selectedShotNoOnAddShot, setSelectedShotNoOnAddShot] = useState(1)
  const [shotNoError, setShotNoError] = useState(null)
  const [shotPointError, setShotPointError] = useState(null)
  const [selectedShotPointOnAddShot, setSelectedShotPointOnAddShot] = useState(null)

  // Instruction popup
  const [showInstructionPopup, setShowhowInstructionPopup] = useState(false)
  const [showShotPointSubmitPopup, setShowShotPointSubmitPopup] = useState(false)

  // bounds
  const raw_bounds = [[33.5009737578817237, -82.0251461312958696], [33.5060805713145342, -82.0185544924200940]]
  const southWest = L.latLng(raw_bounds[0][0], raw_bounds[0][1])
  const northEast = L.latLng(raw_bounds[1][0], raw_bounds[1][1])
  const bounds = L.latLngBounds(southWest, northEast)
  // Map and map zoom
  const [map, setMap] = useState(null)
  const [mapZoom, setMapZoom] = useState(17)

  const handleAddPlayer = e => {
    e.preventDefault()
    const playerNo = 'P' + padToFour(data.length + 1)
    let firstName = firstNameRef.current.value;
    let lastName = lastNameRef.current.value;
    if (firstName && lastName) {
      firstName = capitalize(firstName)
      lastName = capitalize(lastName)
      const fullName = `${lastName}, ${firstName}`
      firestore.collection('data').add({
        playerNo,
        firstName,
        lastName,
        fullName
      }).catch(e => alert(e))

      firstNameRef.current.value = ""
      lastNameRef.current.value = ""
    }
  }

  function handleSelectLatLng() {
    setShowhowInstructionPopup(false)
    $('.leaflet-container').css('cursor', "crosshair")

    map.on('click', e => {
      if (e.latlng) {
        setSelectedShotPointOnAddShot(e.latlng)
        $('.leaflet-container').css('cursor', "grab")
        setShowShotPointSubmitPopup(true)
        map.off('click')
      }
    })
  }

  function resetShot() {
    setShowhowInstructionPopup(false)
    setSelectedPlayerOnAddShot(null)
    setSelectedRoundOnAddShot(null)
    setSelectedShotNoOnAddShot(1)
    setSelectedShotPointOnAddShot(null)
    setShotNoError(null)
    setShotPointError(null)
  }

  async function shotPointSubmit() {
    const playerId = selectedPlayerOnAddShot.id
    const roundNo = selectedRoundOnAddShot.toString()
    const shotNo = selectedShotNoOnAddShot.toString()
    const shotPoint = selectedShotPointOnAddShot

    const roundDoc = firestore.collection('shot_data').doc(playerId).collection(roundNo).doc(roundNo)

    const doc = await roundDoc.get()


    const shotsArrayUnion = { shots: firebase.firestore.FieldValue.arrayUnion(Number(shotNo)) }

    if (doc.exists)
      await roundDoc.update(shotsArrayUnion)
    else
      await roundDoc.set(shotsArrayUnion)

    await roundDoc.collection(shotNo).doc(shotNo).set({ shotPoint: { lat: shotPoint.lat, lng: shotPoint.lng } })

    resetShot()
    setShowShotPointSubmitPopup(false)

    const activeRoundElem = $('.round.main.active')
    if (activeRoundElem) {
      activeRoundElem.trigger('click')
      setTimeout(() => {
        activeRoundElem.trigger('click')
      }, 500)
    }
  }

  async function handleRoundChange() {

    const doc = await firestore.collection('courses').doc('Augusta').get()

    const data = doc.data()

    // start and end latlng
    const startLatLng = data.start
    const endLatLatLng = data.rounds[selectedRound - 1]



    // start and end marker
    const startMarker = L.marker(startLatLng)
    const endMarker = L.marker(endLatLatLng, { icon: redMarker })


    startMarker.id = `start${selectedRound}`
    endMarker.id = `end${selectedRound}`

    const startCircle = getCircleMarker(startLatLng)
    const endCircle = getCircleMarker(endLatLatLng)
    startCircle.id = 'circle-marker'
    endCircle.id = 'circle-marker'


    const distanceStartToEnd = L.latLng(startLatLng).distanceTo(endLatLatLng)
    const startPopup = getMarkerPopup(`<div class="marker-popup"> <div> Tee </div> Distance to hole: ${Math.round(distanceStartToEnd * 1.09361)} yds </div>`)
    const endPopup = getMarkerPopup(`<div class="marker-popup"> <div> Hole </div> </div>`)

    // Add popup to start marker
    startMarker.addEventListener('mouseover', e => { e.target.openPopup() })
    startMarker.addEventListener('mouseout', e => { e.target.closePopup() })
    startMarker.bindPopup(startPopup);

    // Add popup to end marker
    endMarker.addEventListener('mouseover', e => { e.target.openPopup() })
    endMarker.addEventListener('mouseout', e => { e.target.closePopup() })
    endMarker.bindPopup(endPopup);

    endCircle.addTo(map)
    startCircle.addTo(map)

    // Add markers to map
    startMarker.addTo(map)
    endMarker.addTo(map)


    if (selectedPlayer) {
      const roundStr = selectedRound.toString()
      const roundRef = firestore.doc(`shot_data/${selectedPlayer.id}/${roundStr}/${roundStr}`)

      const roundDoc = await roundRef.get()

      const shotlatlngs = []

      if (roundDoc.exists) {
        const shots = roundDoc.data().shots
        for (let i = 0; i < shots.length; i++) {
          let shot = shots[i]
          shot = shot.toString()
          const shotDoc = await roundRef.collection(shot).doc(shot).get()
          if (shotDoc.exists) {
            const data = shotDoc.data()
            shotlatlngs.push(data.shotPoint)
          }
        }
        if (shotlatlngs) {
          const polyline = [startLatLng, ...shotlatlngs, endLatLatLng]
          const distances = calculateDistances(polyline)

          for (let i = 0; i < shotlatlngs.length; i++) {
            const shotPoint = shotlatlngs[i]
            const shotdistances = distances[i]

            const shotMarker = L.marker(shotPoint).addTo(map)
            shotMarker.id = `shot${selectedRound}-${i + 1}`

            const shotCircle = getCircleMarker(shotPoint).addTo(map);
            shotCircle.id = 'circle-marker'

            const shotPopup = getMarkerPopup(`<div class="marker-popup"> <div> Shot No: ${i + 1} </div> Distance covered: ${Math.round(shotdistances.distanceToPre * 1.09361)} yds </br> Distance to hole: ${Math.round(shotdistances.distanceToHole * 1.09361)} yds </div>`)
            shotMarker.addEventListener('mouseover', e => e.target.openPopup())
            shotMarker.addEventListener('mouseout', e => e.target.closePopup())
            // bind with popup
            shotMarker.bindPopup(shotPopup)
          }
          const shotline = L.polyline(polyline, { color: 'yellow' }).addTo(map)
          shotline.id = `shot-line-${selectedRound}`
        }
        else {
          const latlngs = [startLatLng, endLatLatLng]
          const line = L.polyline(latlngs, { color: 'yellow' }).addTo(map)
          line.id = `start-end-line${selectedRound}`
        }
      }
      else {
        const latlngs = [startLatLng, endLatLatLng]
        const line = L.polyline(latlngs, { color: 'yellow' }).addTo(map)
        line.id = `start-end-line${selectedRound}`
      }
    }
    else {
      const latlngs = [startLatLng, endLatLatLng]
      const line = L.polyline(latlngs, { color: 'yellow' }).addTo(map)
      line.id = `start-end-line${selectedRound}`
    }
  }

  const removeMarkers = () => {
    if (map) {
      for (let key in map._layers) {
        if (map._layers[key].id) {
          map.removeLayer(map._layers[key])
        }
      }
    }
  }

  const handleRoundClick = e => {
    const targetElement = $(e.currentTarget)
    if (targetElement.hasClass('active')) {
      targetElement.removeClass('active')
      removeMarkers()
      setSelectedRound(null)
    } else {
      $('.round.main').removeClass('active')
      targetElement.addClass('active')
      const round = parseInt(targetElement.data('roundNo'))
      removeMarkers()
      setSelectedRound(round)
    }
  }

  useEffect(() => { L.Marker.prototype.options.icon = defaultMarker; }, [])

  useEffect(() => {

    if (selectedRound) {
      $('.round.main').off('click')
      handleRoundChange().then(() => {

        $('.round.main').on('click', handleRoundClick)

      })
    }

  }, [selectedRound])



  useEffect(() => {
    if (map) {
      $('.leaflet-control-attribution').hide()

      $('.round.main').on('click', handleRoundClick)

      function resetMap() {
        const screenSize = $(window).width()
        const zoomLevel = Math.round(mappedValue(screenSize))
        map.setMinZoom(zoomLevel)
        map.setZoom(zoomLevel)
        setMapZoom(zoomLevel)
        map.setView(bounds.getCenter())
      }
      resetMap()
      $(window).on('resize', resetMap)
      const homeBtn = L.easyButton("fa-home", resetMap, 'Home')
      homeBtn.addTo(map)
    }

  }, [map]);

  useEffect(() => {
    $('.round.main').removeClass('active')
    removeMarkers()
    setSelectedRound(null)
  }, [selectedPlayer])

  useEffect(() => {
    $('.round.add-shot').on('click', e => {
      const targetElement = $(e.currentTarget)
      if (targetElement.hasClass('active')) {
        targetElement.removeClass('active')
        setSelectedRoundOnAddShot(null)
      } else {
        $('.round.add-shot').removeClass('active')
        targetElement.addClass('active')
        const round = parseInt(targetElement.data('roundNo'))
        setSelectedRoundOnAddShot(round)
      }
    })
  }, [showAppShotPopup])

  const shotNoOnchangeHandler = () => {
    setShotNoError(null)
    const value = shotNoRef.current.value
    if (typeof value === 'string') {
      try {
        const number = parseInt(value)
        if (number > 0) {
          setSelectedShotNoOnAddShot(number)
        } else {
          setShotNoError("Please enter a number > 0")
        }
      } catch (error) {
        setShotNoError(error.toString())
      }
    } else {
      setShotNoError("Please enter a number")
    }
  }

  const handleOnShotPointClick = () => {
    setShotPointError(null)
    if (!selectedPlayerOnAddShot) {
      setShotPointError("Please select a player")
      return
    }
    if (!selectedRoundOnAddShot) {
      setShotPointError("Please select a round")
      return
    }
    if (shotNoError) {
      setShotPointError("Please enter a valid shot no")
      return
    }
    setShowAddShotPopup(false)
    setShowhowInstructionPopup(true)
  }

  const AddPlayer = <Modal dialogClassName="add-player-modal" centered size="lg" show={showAddPlayerPopup} onHide={() => setShowAddPlayerPopup(false)}>
    <Modal.Body id="add-player-body">

      <form onSubmit={handleAddPlayer}>
        <input type="text" placeholder="First Name" ref={firstNameRef} />
        <input type="text" placeholder="Last Name" ref={lastNameRef} />
        <button type="submit">Add</button>
      </form>

      <span>Total no. of players: {data && data.length}</span>

      <table>
        <thead>
          <tr>
            <th>Player No.</th>
            <th>Player Name</th>
          </tr>
        </thead>
        <tbody>
          {
            data && data.map(player => <tr key={player.id}>
              <td>{player.playerNo}</td>
              <td>{player.fullName}</td>
            </tr>
            )
          }
        </tbody>
      </table>

    </Modal.Body>
  </Modal>

  const AddShot = <Modal dialogClassName="add-shot-modal" centered size="lg" show={showAppShotPopup} onHide={() => setShowAddShotPopup(false)}>
    <Modal.Body id="add-shot-body">

      <Dropdown >
        <Dropdown.Toggle>
          {selectedPlayerOnAddShot ? selectedPlayerOnAddShot.fullName : "select player"}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {
            data && data.map(player => <Dropdown.Item
              key={player.id}
              className="player-op"
              onClick={() => setSelectedPlayerOnAddShot(player)}
            >
              {player.fullName}
            </Dropdown.Item>)
          }
        </Dropdown.Menu>
      </Dropdown>

      <div className="choose-round">
        <div className="round add-shot" data-round-no={1}>R1</div>
        <div className="round add-shot" data-round-no={2}>R2</div>
        <div className="round add-shot" data-round-no={3}>R3</div>
        <div className="round add-shot" data-round-no={4}>R4</div>
      </div>

      <input type="text" placeholder="shot no" defaultValue={selectedShotNoOnAddShot} ref={shotNoRef} onChange={shotNoOnchangeHandler} />
      {
        shotNoError && <span>{shotNoError}</span>
      }
      <button onClick={handleOnShotPointClick}>shot point</button>
      {
        shotPointError && <span>{shotPointError}</span>
      }

    </Modal.Body>
  </Modal>

  const Instructions = <Modal dialogClassName="instruction-modal" backdrop="static" centered size="lg" show={showInstructionPopup} onHide={() => setShowhowInstructionPopup(false)}>
    <Modal.Body id="instruction-body">
      <h4>Continue to position the shot on the map</h4>

      <div className="buttons">
        <button onClick={() => {
          resetShot();
          $('.leaflet-container').css('cursor', "grab");
        }}>Exit</button>
        <button onClick={handleSelectLatLng}>Continue</button>
      </div>
    </Modal.Body>
  </Modal>

  const SubmitShotPoint = <Modal dialogClassName="submit-shot-point-modal" backdrop="static" centered size="lg" show={showShotPointSubmitPopup} onHide={() => setShowShotPointSubmitPopup(false)}>
    <Modal.Body id="submit-shot-point-body">

      <div className="box">
        <img src={ProfilePic} alt="profile picture" />

        <div className="right">
          {
            selectedPlayerOnAddShot && <p>{selectedPlayerOnAddShot.fullName}</p>
          }
          {
            selectedRoundOnAddShot && <p>Round {selectedRoundOnAddShot}</p>
          }
          {
            selectedShotNoOnAddShot && <p>Shot {selectedShotNoOnAddShot}</p>
          }
          {
            selectedShotPointOnAddShot && <p>{selectedShotPointOnAddShot.lat.toFixed(6)} {selectedShotPointOnAddShot.lng.toFixed(6)}</p>
          }
        </div>
      </div>

      <div className="buttons">
        <button onClick={() => { resetShot(); setShowShotPointSubmitPopup(false); }}>Cancel</button>
        <button onClick={async () => await shotPointSubmit()}>Submit</button>
      </div>

    </Modal.Body>
  </Modal>

  return (
    <div className="App">

      {AddPlayer}
      {AddShot}
      {Instructions}
      {SubmitShotPoint}

      <h1>Augusta National Golf Club · Hole 1</h1>

      <div id="top-bar">
        <div className="left">

          <Dropdown >
            <Dropdown.Toggle id="select-player">
              {selectedPlayer ? selectedPlayer.fullName : "select player"}
            </Dropdown.Toggle>
            <Dropdown.Menu id="dropdown-menu">
              {
                data && data.map(player => <Dropdown.Item
                  key={player.id}
                  className="player-op"
                  onClick={() => {
                    const currentPlayer = player;
                    setSelectedPlayer(player);
                  }}
                >
                  {player.fullName}
                </Dropdown.Item>
                )
              }
            </Dropdown.Menu>
          </Dropdown>

          <div className="choose-round">
            <div className="round main" data-round-no={1}>R1</div>
            <div className="round main" data-round-no={2}>R2</div>
            <div className="round main" data-round-no={3}>R3</div>
            <div className="round main" data-round-no={4}>R4</div>
          </div>
        </div>

        <div className="right">
          <button onClick={() => setShowAddPlayerPopup(true)}>add player</button>
          <button onClick={() => { resetShot(); setShowAddShotPopup(true); }}>add shot</button>
        </div>
      </div>

      <MapContainer
        id="map"
        minZoom={mapZoom}
        maxZoom={20}
        center={bounds.getCenter()}
        zoom={mapZoom}
        whenCreated={setMap}
        crs={CRS.Simple}
      >

        <ImageOverlay
          url={HoleMap}
          bounds={bounds}
        />
      </MapContainer>

    </div>
  );
}

export default App;
