import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyD4k-oXFqls6RoD1OVyis-dMpcPpL0-snI",
    authDomain: "golf-playertracker.firebaseapp.com",
    projectId: "golf-playertracker",
    storageBucket: "golf-playertracker.appspot.com",
    messagingSenderId: "864895343446",
    appId: "1:864895343446:web:b24057344c76d9da6dd4f7"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();

export { firestore };

export default firebase;